import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconShare: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" boxSize={3} {...props}>
        <path
            d="M17.334 15.6c1.473 0 2.667 1.209 2.667 2.7S18.807 21 17.334 21s-2.667-1.209-2.667-2.7 1.194-2.7 2.667-2.7zM6.667 9.3c1.473 0 2.667 1.209 2.667 2.7s-1.194 2.7-2.667 2.7S4 13.491 4 12s1.194-2.7 2.667-2.7zM17.334 3c1.473 0 2.667 1.209 2.667 2.7s-1.194 2.7-2.667 2.7-2.667-1.209-2.667-2.7S15.861 3 17.334 3zM8.961 13.355l6.079 3.59m0-9.89l-6.079 3.59"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
        />
    </Icon>
);
