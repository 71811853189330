import { QueryFunctionContext, useInfiniteQuery as _useInfiniteQuery, UseInfiniteQueryOptions, QueryKey } from "react-query";
import { ILocale } from "src/consts/locale";
import { MAX_ATTEMPTS, RETRY_DELAY_MS } from "src/consts/retry";
import { buildQueryUrl } from "src/utils/query-util";
import { useRouterLocale } from "src/utils/router/use-router-locale";

type THttpMethod = "GET" | "POST" | "PUT" | "DELETE";

interface IQueryFn {
    _url: string;
    locale: ILocale;
    queryParams?: URLSearchParams;
    pageParamOptions?: TPageParamOptions;
    body?: string;
    method?: THttpMethod;
}

const getQueryFn =
    <Data>({ _url, locale, queryParams, pageParamOptions, body, method }: IQueryFn) =>
    async ({
        pageParam = pageParamOptions?.defaultPageParamNumber !== undefined ? pageParamOptions.defaultPageParamNumber : 1,
    }: QueryFunctionContext): Promise<Data> => {
        const params = queryParams ? queryParams : new URLSearchParams();
        const pageParamKeyName = pageParamOptions?.pageParamName ?? "page";

        if (params.has(pageParamKeyName)) {
            params.delete(pageParamKeyName);
        }
        params.append(pageParamKeyName, pageParam);

        const url = buildQueryUrl(locale, _url, params);
        const response = await fetch(url.toLowerCase(), {
            ...(body !== undefined && { body }),
            ...(method !== undefined && { method }),
            headers: {
                Accept: "application/json",
                ...(method === "POST" && { "Content-Type": "application/json" }),
            },
        } as RequestInit);

        const responseValue: Data = await response.json();
        if (!response.ok) {
            throw responseValue;
        }

        return responseValue;
    };

export interface IInfiniteQueryOptions<Data, Error> extends UseInfiniteQueryOptions<Data, Error> {
    queryParams?: URLSearchParams;
    body?: string;
    method?: THttpMethod;
}

type TPageParamOptions = {
    pageParamName: string;
    defaultPageParamNumber: number;
};

export const useInfiniteQuery = <Data = unknown, Error = unknown>(
    _url: string,
    options?: IInfiniteQueryOptions<Data, Error>,
    pageParamOptions?: TPageParamOptions,
    queryKey?: QueryKey
) => {
    const { language, country } = useRouterLocale();
    const retryOptions = options?.method === "POST" ? {} : { retry: MAX_ATTEMPTS, retryDelay: RETRY_DELAY_MS };

    return _useInfiniteQuery<Data, Error>({
        queryKey: queryKey ? queryKey : _url,
        queryFn: getQueryFn<Data>({
            _url,
            locale: { language, country },
            queryParams: options?.queryParams,
            pageParamOptions,
            body: options?.body,
            method: options?.method,
        }),
        ...retryOptions,
        ...options,
    });
};
