import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconClosed: TIconComponent = props => (
    <Icon viewBox="0 0 80 80" {...props}>
        <g fill="none" fillRule="evenodd">
            <path
                d="M65 52.589l1.517-2.87c8.856-17.62 8.8-33.841 8.308-41.244-8.491-2.117-19.985-4.091-33.63-3.928A132.47 132.47 0 0 0 10.73 8.474c-.651 6.98-1.201 23.184 7.517 40.851C25.941 64.917 37.051 73.945 42.778 78a81.29 81.29 0 0 0 14.481-13.94"
                fill="#95c11f"
            />
            <path
                d="M60.596 50.052l1.517-2.87c8.856-17.62 8.8-33.841 8.308-41.244-8.491-2.117-19.985-4.091-33.63-3.928A132.47 132.47 0 0 0 6.326 5.938c-.651 6.98-1.201 23.184 7.517 40.851 7.694 15.592 18.804 24.62 24.531 28.675a81.29 81.29 0 0 0 14.481-13.94"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.57"
                stroke="#333333"
            />
            <path
                d="M38.298 51.704h0c-8.65 0-15.662-6.962-15.662-15.549v-5.953c0-2.144 1.751-3.883 3.911-3.883H50.05c2.16 0 3.911 1.738 3.911 3.883v5.953c0 8.588-7.012 15.549-15.662 15.549"
                fill="#ffffff"
            />
            <path
                d="M38.298 51.704h0c-8.65 0-15.662-6.962-15.662-15.549v-5.953c0-2.144 1.751-3.883 3.911-3.883H50.05c2.16 0 3.911 1.738 3.911 3.883v5.953c0 8.588-7.012 15.549-15.662 15.549z"
                strokeLinejoin="round"
                strokeWidth="1.57"
                stroke="#333333"
            />
            <path d="M38.298 10.821h0c-5.887 0-10.659 4.738-10.659 10.582v4.915h21.318v-4.915c0-5.844-4.772-10.582-10.659-10.582" fill="#ffffff" />
            <path
                d="M38.298 10.821h0c-5.887 0-10.659 4.738-10.659 10.582v4.915h21.318v-4.915c0-5.844-4.772-10.582-10.659-10.582z"
                stroke="#333333"
                strokeWidth="1.57"
                strokeLinejoin="round"
            />
            <path d="M38.298 13.487h0c-4.398 0-7.963 3.54-7.963 7.906v4.926h15.927v-4.926c0-4.366-3.565-7.906-7.963-7.906" fill="#95c11f" />
            <path
                d="M38.298 13.487h0c-4.398 0-7.963 3.54-7.963 7.906v4.926h15.927v-4.926c0-4.366-3.565-7.906-7.963-7.906z"
                stroke="#333333"
                strokeWidth="1.57"
                strokeLinejoin="round"
            />
            <path
                d="M42.174 35.15c0-2.125-1.735-3.848-3.876-3.848s-3.877 1.723-3.877 3.848c0 1.552.928 2.886 2.261 3.495v5.128a1.61 1.61 0 1 0 3.231 0v-5.128c1.333-.609 2.261-1.943 2.261-3.495"
                fill="#95c11f"
            />
            <path
                d="M42.174 35.15c0-2.125-1.735-3.848-3.876-3.848s-3.877 1.723-3.877 3.848c0 1.552.928 2.886 2.261 3.495v5.128a1.61 1.61 0 1 0 3.231 0v-5.128c1.333-.609 2.261-1.943 2.261-3.495z"
                strokeLinejoin="round"
                strokeWidth="1.57"
                stroke="#333333"
            />
        </g>
    </Icon>
);
