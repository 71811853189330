import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconSms: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g
            transform="translate(6.0002 3)"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <path d="m10 18h-8c-1.105 0-2-0.8055-2-1.8v-14.4c0-0.9945 0.895-1.8 2-1.8h8c1.104 0 2 0.8055 2 1.8v14.4c0 0.9945-0.896 1.8-2 1.8z" />
            <polygon points="3.9998 3 7.9998 3 7.9998 3" />
        </g>
    </Icon>
);
