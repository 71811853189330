import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconNotAvailable: TIconComponent = props => (
    <Icon viewBox="0 0 80 80" {...props}>
        <g transform="translate(2 2)" fill="none" fillRule="evenodd">
            <g transform="translate(3.166 3.372)">
                <path
                    d="M72.834 36.314c0 20.056-16.304 36.314-36.417 36.314S0 56.37 0 36.314 16.304 0 36.417 0s36.417 16.258 36.417 36.314"
                    fill="#95c11f"
                />
            </g>
            <path d="M16.258 38.81h0c11.989-15.894 27.408-16.121 40.129 0h0 0c-12.079 15.922-27.502 16.092-40.129 0z" fill="#fff" />
            <path
                d="M16.258 38.81h0c11.989-15.894 27.408-16.121 40.129 0h0 0c-12.079 15.922-27.502 16.092-40.129 0h0z"
                stroke="#333"
                strokeWidth="1.524"
                strokeLinejoin="round"
            />
            <path
                d="M30.303 38.81c0-3.315 2.695-6.002 6.019-6.002s6.019 2.687 6.019 6.002-2.695 6.002-6.019 6.002-6.019-2.687-6.019-6.002"
                fill="#95c11f"
            />
            <g stroke="#333" strokeWidth="1.524">
                <g strokeLinejoin="round">
                    <path d="M30.303 38.81c0-3.315 2.695-6.002 6.019-6.002s6.019 2.687 6.019 6.002-2.695 6.002-6.019 6.002-6.019-2.687-6.019-6.002z" />
                    <path d="M36.322 10.799v8.003m-14.045 4.002l-4.013-6.002m32.103 6.002l4.013-6.002" strokeLinecap="round" />
                </g>
                <path d="M72.834 36.314c0 20.056-16.304 36.314-36.417 36.314S0 56.37 0 36.314 16.304 0 36.417 0s36.417 16.258 36.417 36.314z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M1.775 13.182l69.284 48.675" />
            </g>
        </g>
    </Icon>
);
