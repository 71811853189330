import { Box } from "@chakra-ui/react";
import { Button, ButtonProps } from "src/components/base/button";
import { WfPopover } from "src/components/base/wf-popover";
import { ITooltip } from "src/types/common";
import { EnumTagColor } from "src/types/common-enum";

export interface ITag {
    isCrossedOut?: boolean;
    label: string;
    tooltip?: ITooltip | string;
    // Dev-Note: Unfortunately, there are multiple almost identical WikifolioTag
    // definitions in the backend, which we can't unify yet. So here we try to map
    // them as well as possible into one ITag type.
    tooltipRaw?: ITooltip;
    color: EnumTagColor;
}

export const Tag = ({ label, tooltip, tooltipRaw, color, isCrossedOut, ...buttonProps }: ITag & ButtonProps) => {
    const tt = typeof tooltip === "string" ? tooltipRaw : tooltip;

    const renderButton = () => (
        <Button variant="outline" size="xs" rounded="16px" colorScheme={color} {...buttonProps}>
            <Box {...(isCrossedOut ? { textDecorationLine: "line-through", textDecorationThickness: "2px" } : {})}>{label}</Box>
        </Button>
    );

    if (!tt) {
        return renderButton();
    }

    return <WfPopover {...tt}>{renderButton()}</WfPopover>;
};
