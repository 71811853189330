import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconTrade: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" boxSize={3} {...props}>
        <path
            fill="currentColor"
            d="M5.002 10.5H2.998C1.905 10.5 1 11.36 1 12.448v7.604C1 21.14 1.905 22 2.998 22h2.004C6.095 22 7 21.14 7 20.052v-7.604C7 11.36 6.095 10.5 5.002 10.5zM5 12.5V20H3v-7.5h2zm16.002-8.65h-2.004C17.905 3.85 17 4.71 17 5.798v14.254C17 21.14 17.905 22 18.998 22h2.004C22.095 22 23 21.14 23 20.052V5.798c0-1.088-.905-1.948-1.998-1.948zm-.002 2V20h-2V5.85h2zM13.002 2h-2.004C9.916 2 9 2.824 9 3.898v16.204C9 21.176 9.916 22 10.998 22h2.004C14.084 22 15 21.176 15 20.102V3.898C15 2.824 14.084 2 13.002 2zM13 4v16h-2V4h2z"
        />
    </Icon>
);
