import { createAddSmartFeedItemSeenActivityUrl } from "src/consts/urls";
import { useMutation } from "src/hooks/react-query/use-mutation";
import { useRouterLocale } from "src/utils/router/use-router-locale";

interface IFeedActivityRequest {
    ids: string[];
    parameter?: string;
}

export function useFeedSeenActivityMutation() {
    const { language, country } = useRouterLocale();

    const { mutatePromise } = useMutation<void, unknown, IFeedActivityRequest>(createAddSmartFeedItemSeenActivityUrl(language, country));

    return mutatePromise;
}
