import { ReactNode } from "react";
import { Box, Flex, forwardRef, VStack } from "@chakra-ui/react";

const FEED_CARD_HEADER_TEST_ID = "feed-card-header";
const FEED_CARD_FOOTER_TEST_ID = "feed-card-footer";

const FeedCardLayoutBase = forwardRef(({ children, ...props }, ref) => (
    <Box wordBreak="break-word" backgroundColor="white" boxShadow="sm" px={[2, 5]} borderRadius="8px" ref={ref} {...props}>
        {children}
    </Box>
));

interface IFeedCardLayoutType {
    children: ReactNode;
}

interface IFeedCardLayoutHeaderProps extends IFeedCardLayoutType {
    isContextMenuVisible?: boolean;
}

const FeedCardLayoutHeader = ({ children, isContextMenuVisible }: IFeedCardLayoutHeaderProps) => (
    <Flex
        pr={isContextMenuVisible ? 0 : 5}
        minHeight={[5, 6]}
        justifyContent="space-between"
        alignItems="center"
        borderBottom="1px"
        borderColor="gray.100"
        fontSize={["xs", "sm"]}
        data-test-id={FEED_CARD_HEADER_TEST_ID}
    >
        {children}
    </Flex>
);

const FeedCardLayoutContent = ({ children }: IFeedCardLayoutType) => (
    <VStack py={2} spacing={2} fontSize="sm" align="stretch">
        {children}
    </VStack>
);

const FeedCardLayoutFooter = ({ children }: IFeedCardLayoutType) => (
    <Flex py={2} borderTop="1px" borderColor="gray.100" alignItems="center" data-test-id={FEED_CARD_FOOTER_TEST_ID}>
        {children}
    </Flex>
);

export const FeedCardLayout = {
    Base: FeedCardLayoutBase,
    Header: FeedCardLayoutHeader,
    Content: FeedCardLayoutContent,
    Footer: FeedCardLayoutFooter,
};
