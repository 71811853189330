import { proxy } from "valtio";

export enum EnumPageType {
    WikifolioDetail = "WikifolioDetail",
    Feed = "Feed",
    ExternalNews = "ExternalNews",
}
export interface IFeedTrackingStore {
    page?: EnumPageType;
    wikifolioId?: string;
    feedType?: string;
    setFeedType: (feedType?: string) => void;
}

export interface IFeedTrackingParams {
    page: EnumPageType;
    wikifolioId?: string;
    feedType?: string;
}

export const feedTrackingStore = proxy({
    page: undefined,
    wikifolioId: undefined,
    feedType: undefined,
    setFeedType(feedType?: string) {
        feedTrackingStore.feedType = feedType;
    },
} as IFeedTrackingStore);

export const setTrackingStoreFeedType = (feedType?: string) => {
    feedTrackingStore.setFeedType(feedType === "" ? undefined : feedType);
};

export function initializeFeedTrackingStore(params: IFeedTrackingParams) {
    feedTrackingStore.page = params.page;
    feedTrackingStore.wikifolioId = params.wikifolioId;
    feedTrackingStore.feedType = params.feedType;
}
