import { ReactElement } from "react";
import { useTranslation, TFunction } from "next-i18next";
import { IconMail, IconSms, IconFacebook, IconTwitter, IconWhatsApp } from "src/components/icons";
import { IShareOption } from "./share-button";

const shareKeys = ["facebook", "twitter", "mail", "sms", "whatsapp"] as const;
export type TShareOption = (typeof shareKeys)[number];

const handheldShareLinks: ReadonlyArray<TShareOption> = ["sms", "whatsapp"];

type IShareLinkMap<T = string> = {
    [key in TShareOption]: T;
};

const iconMap: IShareLinkMap<ReactElement> = {
    facebook: <IconFacebook color="gray.800" boxSize={3} mr={1} />,
    twitter: <IconTwitter color="gray.800" boxSize={3} mr={1} />,
    mail: <IconMail boxSize={3} mr={1} />,
    sms: <IconSms boxSize={3} mr={1} />,
    whatsapp: <IconWhatsApp boxSize={3} mr={1} />,
};

const shareLinkConfigBasic: IShareLinkMap = {
    facebook: "https://www.facebook.com/sharer/sharer.php?u={1}&title={0}",
    twitter: "https://twitter.com/intent/tweet?text={0}%20{1}%20%23socialtrading%20%23wikifolio&source=webclient",
    mail: "mailto:?subject={0}&body={2}%20{1}",
    sms: "sms:?&body={2}%20-%20{1}",
    whatsapp: "whatsapp://send?text={2}%20-%20{1}",
};

const createShareLinkConfigUrlOnly = (t: TFunction): IShareLinkMap => ({
    facebook: "https://www.facebook.com/sharer/sharer.php?u={0}",
    twitter: "https://twitter.com/intent/tweet?text={0}%20%23socialtrading%20%23wikifolio&source=webclient",
    mail: `mailto:?subject=${t("share-email-subject")}&body={0}`,
    sms: "sms:?&body={0}",
    whatsapp: "whatsapp://send?text={0}",
});

const getShareOptionFullTranslationKey = (key: string) => `labels.share-options.${key}`;

const createShareLink = (title: string, url: string, description: string, type: TShareOption, config: IShareLinkMap, t: TFunction): IShareOption => ({
    key: type,
    label: t(getShareOptionFullTranslationKey(type)),
    icon: iconMap[type],
    link: config[type].format(encodeURIComponent(title), encodeURIComponent(url), encodeURIComponent(description)),
    isHandheld: handheldShareLinks.includes(type),
});

export const useShareLinksBasic = (title: string, description: string, url: string) => {
    const { t } = useTranslation("common");
    return shareKeys.map(key => createShareLink(title, url, description, key, shareLinkConfigBasic, t));
};

const getShareOptionUrl = (url: string, type: TShareOption) => {
    if (!url) {
        return;
    }

    const shareableUrl = new URL(url);
    shareableUrl.searchParams.set("utm_source", type);
    return encodeURIComponent(shareableUrl.href);
};

export const useShareLinksForComment = (url: string) => {
    const { t } = useTranslation("common");
    const shareLinkConfigUrlOnly = createShareLinkConfigUrlOnly(t);

    const createShareLinkComment = (type: TShareOption): IShareOption => {
        return {
            key: type,
            label: t(getShareOptionFullTranslationKey(type)),
            icon: iconMap[type],
            link: shareLinkConfigUrlOnly[type].format(getShareOptionUrl(url, type)),
            isHandheld: handheldShareLinks.includes(type),
        };
    };

    return shareKeys.map(key => createShareLinkComment(key));
};
