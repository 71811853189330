import { useCallback, useMemo } from "react";
import debounce from "lodash.debounce";
import { useFeedSeenActivityMutation } from "src/api/client/feed-seen-activity.api";
import { feedTrackingStore } from "src/components/feed-card/store/feed-tracking-store";
import { ContentSeenItem, SeenItem, TSeenItemsRef } from "../types/types";

const ALL_KEY = "all";
const TRACKED_KEY = "tracked";

type TGetTrackedMapKeyParams = {
    feedTypeValue?: string;
    TRACKED_KEY: string;
    ALL_KEY: string;
};

const getTrackedMapKey = ({ feedTypeValue, TRACKED_KEY, ALL_KEY }: TGetTrackedMapKeyParams): string =>
    feedTypeValue ? `${TRACKED_KEY}-${feedTypeValue}` : `${TRACKED_KEY}-${ALL_KEY}`;

type TSaveTrackedContentIdsParams = {
    trackedMapKey: string;
    contentId: string;
    seenItemsRef: TSeenItemsRef;
};
const saveTrackedContentIds = ({ trackedMapKey, contentId, seenItemsRef }: TSaveTrackedContentIdsParams): string[] => {
    const contentSeenItem = seenItemsRef.current.get(trackedMapKey);
    const trackedItems = contentSeenItem?.contentIds ?? [];
    const uniqueUpdatedItems = {
        seenItem: getCurrentSeenItem(),
        contentIds: [...new Set([...trackedItems, contentId])],
    } as ContentSeenItem;
    seenItemsRef.current.set(trackedMapKey, uniqueUpdatedItems);

    return [...trackedItems];
};

type TUpdateSeenItemsBasedOnFeedTypeParams = {
    contentId: string;
    feedTypeValue?: string;
    seenItemsRef: TSeenItemsRef;
};

const getCurrentSeenItem = (): SeenItem => {
    return {
        feedTypeValue: feedTrackingStore.feedType,
        page: feedTrackingStore.page,
        wikifolioId: feedTrackingStore.wikifolioId,
    };
};

const updateSeenItemsBasedOnFeedType = ({ feedTypeValue, contentId, seenItemsRef }: TUpdateSeenItemsBasedOnFeedTypeParams): void => {
    if (feedTypeValue) {
        const contentSeenItem = seenItemsRef.current.get(feedTypeValue);
        const prevItems = contentSeenItem?.contentIds || [];
        seenItemsRef.current.set(feedTypeValue, {
            seenItem: contentSeenItem?.seenItem ?? getCurrentSeenItem(),
            contentIds: [...new Set([...prevItems, contentId])],
        } as ContentSeenItem);
    } else {
        const contentSeenItem = seenItemsRef.current.get(ALL_KEY);
        const allItems = contentSeenItem?.contentIds || [];
        seenItemsRef.current.set(ALL_KEY, {
            seenItem: contentSeenItem?.seenItem ?? getCurrentSeenItem(),
            contentIds: [...new Set([...allItems, contentId])],
        } as ContentSeenItem);
    }
};

export const useFeedItemSeenTracking = (seenItemsRef: TSeenItemsRef) => {
    const feedSeenActivityMutatePromise = useFeedSeenActivityMutation();

    const trackSeenItemsInLast2Seconds = useMemo(
        () =>
            debounce(
                () => {
                    seenItemsRef.current.forEach((seenItems, key) => {
                        if (key.includes(`${TRACKED_KEY}-`)) {
                            return;
                        }

                        const activityParams = {
                            ids: seenItems.contentIds,
                            parameter: JSON.stringify({
                                feedType: seenItems.seenItem.feedTypeValue,
                                page: seenItems.seenItem.page,
                                wikifolioId: seenItems.seenItem.wikifolioId,
                            }),
                        };

                        feedSeenActivityMutatePromise(activityParams);
                        seenItemsRef.current.delete(key);
                    });
                },
                2000,
                { trailing: true, maxWait: 2000 }
            ),
        [feedSeenActivityMutatePromise, seenItemsRef]
    );

    const trackSeenItem = useCallback(
        (contentId: string) => {
            const feedTypeValue = feedTrackingStore.feedType;

            // DEV-Note: To ensure consistent tracking even if DOM nodes are removed and references to
            // the 'isSeen' hook are lost, the IDs tracked based on feed type values are stored in 'seenItemsRef'.
            const trackedMapKey = getTrackedMapKey({ feedTypeValue, TRACKED_KEY, ALL_KEY });
            const trackedItems = saveTrackedContentIds({ trackedMapKey, contentId, seenItemsRef });

            if (trackedItems.includes(contentId)) {
                return;
            }

            updateSeenItemsBasedOnFeedType({ feedTypeValue, contentId, seenItemsRef });

            trackSeenItemsInLast2Seconds();
        },
        [trackSeenItemsInLast2Seconds, seenItemsRef]
    );

    return { trackSeenItem };
};
