import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconWikifolioCta: TIconComponent = props => (
    <Icon viewBox="0 0 784.26 437.25" {...props}>
        <g clipPath="url(#clip0_1781_37645)">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M229.13 0C232.37 1.43051e-06 235.258 2.04073 236.341 5.09432L241.212 18.8386C242.71 23.0727 245.975 26.359 250.06 27.8563C250.061 27.8566 250.061 27.8568 250.062 27.857L263.58 32.8063C266.595 33.9102 268.6 36.7793 268.6 39.99C268.6 43.2007 266.595 46.0698 263.58 47.1737L250.062 52.123C250.061 52.1232 250.061 52.1234 250.06 52.1237C245.975 53.621 242.71 56.9073 241.212 61.1414L236.341 74.8857C235.258 77.9393 232.37 79.98 229.13 79.98C225.89 79.98 223.002 77.9393 221.92 74.8857L217.048 61.1414C215.55 56.9073 212.285 53.621 208.2 52.1237C208.2 52.1236 208.2 52.1237 208.2 52.1237C208.199 52.1235 208.199 52.1232 208.198 52.123L194.68 47.1737C191.665 46.0698 189.66 43.2007 189.66 39.99C189.66 36.7793 191.665 33.9102 194.68 32.8063L208.198 27.857C208.199 27.8568 208.199 27.8566 208.2 27.8563C212.285 26.359 215.55 23.0727 217.048 18.8386L221.92 5.09432C223.002 2.04072 225.89 -1.43051e-06 229.13 0ZM229.13 29.0216C231.758 33.5682 235.52 37.3538 240.046 39.99C235.52 42.6262 231.758 46.4118 229.13 50.9584C226.502 46.4118 222.74 42.6262 218.214 39.99C222.74 37.3538 226.502 33.5682 229.13 29.0216Z"
                fill="#333333"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M49.98 249.52C53.2197 249.52 56.1082 251.561 57.1905 254.614L62.062 268.359C63.5599 272.593 66.8252 275.879 70.9101 277.376C70.9105 277.376 70.9109 277.377 70.9113 277.377C70.9111 277.377 70.9115 277.377 70.9113 277.377L84.4301 282.326C87.4451 283.43 89.45 286.299 89.45 289.51C89.45 292.721 87.4451 295.59 84.4301 296.694L70.9119 301.643C70.9113 301.643 70.9107 301.643 70.9101 301.644C66.8252 303.141 63.5599 306.427 62.062 310.661L57.1905 324.406C56.1082 327.459 53.2197 329.5 49.98 329.5C46.7403 329.5 43.8518 327.459 42.7695 324.406L37.898 310.661C36.4001 306.427 33.1348 303.141 29.0499 301.644C29.0493 301.643 29.0487 301.643 29.0482 301.643L15.5299 296.694C12.5149 295.59 10.51 292.721 10.51 289.51C10.51 286.299 12.5149 283.43 15.5299 282.326L29.0482 277.377C29.0487 277.377 29.0493 277.377 29.0499 277.376C33.1348 275.879 36.4001 272.593 37.898 268.359L42.7695 254.614C43.8518 251.561 46.7403 249.52 49.98 249.52ZM49.98 278.542C47.3521 283.088 43.5898 286.874 39.0635 289.51C43.5898 292.146 47.3521 295.932 49.98 300.478C52.608 295.932 56.3702 292.146 60.8965 289.51C56.3702 286.874 52.608 283.088 49.98 278.542Z"
                fill="#333333"
            />
            <path
                d="M703.94 242.32L718.6 327.82L624.75 278.48L530.89 327.82L548.82 223.31L472.89 149.3L577.82 134.05L624.75 38.96L671.68 134.05L776.61 149.3L716.37 208.01"
                fill="#C3F1D6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M624.75 31.3101C627.662 31.3101 630.321 32.9632 631.61 35.5744L676.76 127.058L777.71 141.73C780.592 142.148 782.986 144.167 783.886 146.936C784.785 149.706 784.035 152.746 781.949 154.779L721.709 213.489C718.684 216.437 713.84 216.375 710.891 213.349C707.943 210.324 708.005 205.48 711.031 202.532L760.169 154.641L670.58 141.621C668.088 141.258 665.934 139.693 664.82 137.436L624.75 56.2455L584.68 137.436C583.566 139.693 581.412 141.258 578.92 141.621L489.33 154.641L554.16 217.832C555.963 219.589 556.786 222.122 556.36 224.604L541.051 313.836L621.19 271.709C623.419 270.537 626.081 270.537 628.31 271.709L708.441 313.836L696.4 243.613C695.686 239.449 698.483 235.494 702.647 234.78C706.811 234.066 710.766 236.863 711.48 241.027L726.14 326.527C726.632 329.397 725.452 332.298 723.096 334.009C720.741 335.721 717.617 335.946 715.04 334.591L624.75 287.123L534.45 334.591C531.872 335.946 528.749 335.721 526.393 334.009C524.037 332.297 522.858 329.397 523.35 326.527L540.6 225.981L467.55 154.778C465.465 152.746 464.715 149.706 465.614 146.936C466.514 144.167 468.908 142.148 471.79 141.73L572.74 127.058L617.89 35.5744C619.179 32.9632 621.838 31.3101 624.75 31.3101Z"
                fill="#333333"
            />
            <path
                d="M139.04 147.82L146.56 191.65L93.5 163.76L40.44 191.65L50.57 132.57L7.65002 90.7299L66.97 82.1099L93.5 28.3599L120.03 82.1099L179.35 90.7299L148.93 120.39"
                fill="#C3F1D6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M93.5 20.71C96.4118 20.71 99.0711 22.363 100.36 24.974L125.11 75.1178L180.45 83.1595C183.332 83.5782 185.725 85.5964 186.625 88.3655C187.525 91.1347 186.775 94.1746 184.691 96.2073L154.27 125.867C151.245 128.817 146.402 128.756 143.453 125.73C140.503 122.705 140.564 117.862 143.589 114.913L162.913 96.0718L118.93 89.6805C116.438 89.3184 114.284 87.7536 113.17 85.4959L93.5 45.6441L73.8299 85.4959C72.7155 87.7536 70.5616 89.3184 68.0701 89.6805L24.0887 96.0715L55.91 127.092C57.7127 128.85 58.5354 131.381 58.1099 133.863L50.5991 177.668L89.9406 156.988C92.169 155.817 94.831 155.817 97.0593 156.988L136.399 177.667L131.5 149.114C130.786 144.949 133.582 140.995 137.746 140.28C141.91 139.566 145.865 142.362 146.58 146.526L154.1 190.356C154.592 193.226 153.413 196.127 151.057 197.839C148.701 199.55 145.578 199.776 143.001 198.421L93.5 172.402L43.9993 198.421C41.4219 199.776 38.299 199.55 35.9433 197.839C33.5877 196.127 32.4079 193.227 32.9 190.357L42.3504 135.241L2.30997 96.2078C0.224914 94.1752 -0.525422 91.1352 0.374437 88.3659C1.2743 85.5965 3.6683 83.5782 6.54988 83.1595L61.8901 75.1178L86.6401 24.974C87.9289 22.363 90.5882 20.71 93.5 20.71Z"
                fill="#333333"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M455.558 38.5037C456.312 34.9734 459.43 32.4502 463.04 32.4502H554.2C556.549 32.4502 558.767 33.529 560.217 35.3764C561.668 37.2238 562.189 39.635 561.631 41.9165L553.762 74.1155L553.761 74.1165L549.072 93.3155L549.071 93.3163L542.971 118.276C541.968 122.381 537.828 124.895 533.724 123.891C529.62 122.888 527.106 118.748 528.109 114.644L534.209 89.6849L534.209 89.684L538.899 70.4849L538.899 70.4839L544.455 47.7502H469.23L453.412 121.876L453.412 121.877L435.002 208.186L435.002 208.186L409.372 328.376C408.633 331.841 405.611 334.345 402.069 334.428C398.527 334.511 395.392 332.151 394.491 328.725L348.681 154.42H276.439L230.619 328.725C229.719 332.146 226.592 334.505 223.055 334.428C219.519 334.352 216.496 331.862 215.745 328.405L196.204 238.5H120.79L176.541 421.95H266.182L305.133 267.727C305.991 264.33 309.047 261.95 312.55 261.95C316.054 261.95 319.109 264.33 319.967 267.727L358.908 421.95H452.985L499.078 233.396L499.079 233.394L501.488 223.526C502.491 219.421 506.63 216.906 510.735 217.909C514.839 218.911 517.354 223.05 516.352 227.155L513.942 237.025L513.941 237.027L466.421 431.417C465.584 434.841 462.515 437.25 458.99 437.25H352.95C349.446 437.25 346.391 434.87 345.533 431.473L312.549 300.844L279.557 431.473C278.699 434.87 275.643 437.25 272.14 437.25H170.87C167.502 437.25 164.53 435.047 163.551 431.825L103.151 233.075C102.446 230.756 102.881 228.241 104.324 226.295C105.767 224.348 108.047 223.2 110.47 223.2H202.37C205.969 223.2 209.081 225.709 209.845 229.225L223.925 294.007L263.141 144.825C264.025 141.464 267.064 139.12 270.54 139.12H354.58C358.056 139.12 361.095 141.464 361.979 144.826L401.115 293.735L420.038 204.995L420.038 204.994L438.448 118.684L438.448 118.684L455.558 38.5037Z"
                fill="#333333"
            />
        </g>
        <defs>
            <clipPath id="clip0_1781_37645">
                <rect width="784.26" height="437.25" fill="white" />
            </clipPath>
        </defs>
    </Icon>
);
