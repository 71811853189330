import { proxy, useSnapshot } from "valtio";

export interface IReportModalStore {
    isOpen: boolean;
    entityId: string;
    setIsOpen: (state: boolean) => void;
}

export const reportModalStore = proxy({
    isOpen: false,
    entityId: "",

    setEntityId(entityId: string) {
        reportModalStore.entityId = entityId;
    },

    setIsOpen(state: boolean) {
        reportModalStore.isOpen = state;
    },
});

export function useReportModalStore() {
    return useSnapshot(reportModalStore);
}
