import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconLink: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g fill="currentColor" fillRule="evenodd">
            <path d="m6.9887 10.215c1.8164-1.8164 4.7394-1.8715 6.623-0.16514l0.17348 0.16513 0.088128 0.098032c0.13532 0.17242 0.21179 0.38781 0.21399 0.61278 0.0012858 0.26821-0.10474 0.52669-0.2942 0.71614-0.19093 0.19093-0.44898 0.29678-0.71713 0.29418-0.22359-0.0010917-0.43975-0.07746-0.61258-0.21289l-0.098215-0.088215c-1.0613-1.0613-2.7121-1.1038-3.8199-0.12736l-0.13557 0.12736-3.5837 3.5837c-1.1056 1.1056-1.1056 2.8507 0 3.9555 1.0613 1.0613 2.7121 1.1038 3.8199 0.12736l0.13557-0.12736 3.4022-3.4022c0.18724-0.19248 0.44485-0.30219 0.71199-0.30349 0.26934-0.0026141 0.52739 0.10324 0.71831 0.29417 0.18968 0.18968 0.29548 0.44726 0.29417 0.71732-0.0021737 0.22397-0.078642 0.43919-0.21396 0.6116l-0.088128 0.098029-3.4046 3.4055c-1.8732 1.8732-4.9232 1.8732-6.7965 5e-6 -1.8165-1.8164-1.8715-4.7394-0.16514-6.623l0.16513-0.17348 3.5837-3.5837z" />
            <path d="m13.799 3.4049c1.8732-1.8732 4.9232-1.8732 6.7965-4.92e-6 1.8165 1.8164 1.8715 4.7394 0.16514 6.623l-0.16513 0.17348-3.5837 3.5838c-1.8732 1.8732-4.9232 1.8732-6.7941 0.002363-0.19204-0.18681-0.30186-0.44386-0.30451-0.71318-0.0012813-0.26857 0.10428-0.5259 0.29479-0.71771 0.19064-0.18933 0.44796-0.29489 0.71773-0.29358 0.22418 0.0021864 0.44008 0.078588 0.61201 0.21393l0.097599 0.088156c1.0613 1.0613 2.7121 1.1038 3.8199 0.12736l0.13557-0.12736 3.5837-3.5837c1.1056-1.1056 1.1056-2.8507 0-3.9555-1.0613-1.0613-2.7121-1.1038-3.8199-0.12736l-0.13557 0.12736-3.4016 3.4016c-0.18686 0.1934-0.44452 0.30278-0.71375 0.3041-0.26857 0.0013016-0.5259-0.10425-0.71713-0.29418-0.18946-0.18946-0.29548-0.44793-0.29417-0.71732 0.0021712-0.2237 0.078462-0.43871 0.21387-0.61195l0.088218-0.098664 3.4046-3.4046z" />
        </g>
    </Icon>
);
