import { Menu, MenuItem, MenuList, MenuListProps, MenuProps, useClipboard } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { Span } from "src/components/base";
import { IconLink, IconShare } from "src/components/icons";
import { useWfToast, EnumToastStatus } from "src/hooks/use-wf-toast";
import { triggerCallbackOnMiddleClickEvent } from "src/utils/dom/mouse-util";
import { supportsWebShare } from "src/utils/supports";
import { TShareOption } from "./share-button-util";

export interface IShareOption {
    key: TShareOption;
    link: string;
    label: string;
    icon?: JSX.Element;
    isHandheld?: boolean;
}

export interface IShareButtonProps extends MenuProps {
    link: string;
    shareOptions: IShareOption[];
    webShareData?: {
        url: string;
        title?: string;
        text?: string;
    };
    menuListProps?: MenuListProps;
    onClick?: (key: TShareOption) => void;
    onMiddleClick?: (key: TShareOption) => void;
}

export const ShareButton = ({
    link,
    shareOptions,
    webShareData,
    menuListProps,
    onClick,
    onMiddleClick,
    children,
    ...menuProps
}: IShareButtonProps) => {
    const toast = useWfToast();
    const { onCopy } = useClipboard(link);
    const { t } = useTranslation("common");

    const handleCopy = () => {
        onCopy();
        toast({ status: EnumToastStatus.Info, title: t("link-added-to-clipboard") });
    };

    return (
        <Menu isLazy {...menuProps}>
            <>
                {children}
                <MenuList zIndex="dropdown" {...menuListProps}>
                    {shareOptions.map(option => (
                        <MenuItem
                            key={option.link}
                            as="a"
                            href={option.link}
                            target="_blank"
                            display={option.isHandheld ? ["flex", "flex", "none"] : undefined}
                            onClick={() => onClick?.(option.key)}
                            onAuxClick={triggerCallbackOnMiddleClickEvent(() => onMiddleClick?.(option.key))}
                        >
                            {option.icon && option.icon}
                            {option.label}
                        </MenuItem>
                    ))}
                    <MenuItem onClick={handleCopy}>
                        <IconLink boxSize={3} mr={1} />
                        {t("copy-link")}
                    </MenuItem>
                    {supportsWebShare() && (
                        <MenuItem onClick={() => navigator.share(webShareData)}>
                            <IconShare boxSize={3} mr={1} />
                            <Span textTransform="capitalize">{t("more")}</Span>
                        </MenuItem>
                    )}
                </MenuList>
            </>
        </Menu>
    );
};
