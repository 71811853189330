export enum EnumFeedCardBlockType {
    Bars = "bars",
    Icon = "icon",
    Image = "image",
    Link = "link",
    Number = "number",
    Price = "price",
    RichText = "richtext",
    SmartParagraph = "smartParagraph",
    StackedBarChart = "stackedBarChart",
    Table = "table",
    Tag = "tag",
    Text = "text",
    IconText = "iconText",
    Voting = "voting",
    Actor = "actor",
    Group = "group",
    Headline = "headline",
    Categories = "categories",
    AggregatedReactionsBar = "aggregatedReactionsBar",
    UserReaction = "userReaction",
    StockLoverNews = "stockLoverNews",
    TradingMotive = "tradingMotive",
}

export enum EnumFeedCardLinkVariant {
    Wikifolio = "wikifolio",
    Underlying = "underlying",
    Plain = "plain",
}

export enum EnumFeedCardNumberFormat {
    Default = "default",
    Performance = "performance",
    Percent = "percent",
    IntegerWithSign = "integerWithSign",
}

export enum EnumFeedCardBarSegmentColors {
    Red = "red",
    Green = "green",
}

export enum EnumTaggedEntityType {
    Underlying = "underlying",
    Wikifolio = "wikifolio",
    Comment = "comment",
}

export enum EnumFeedCardCategoryVariant {
    Full = "full",
    Outlined = "outlined",
}

export enum EnumFeedCardTradingMotiveType {
    FOMO = "fomo",
    BuyingTheDip = "buyingTheDip",
    TakingProfits = "takingProfits",
    JumpingTheShip = "jumpingTheShip",
}
