import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconContinue: TIconComponent = props => (
    <Icon viewBox="0 0 96 96" {...props}>
        <svg width="96" height="96" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M90.5999 49.6857C90.5999 69.7415 74.2956 85.9999 54.183 85.9999C34.07 85.9999 17.7656 69.7415 17.7656 49.6857C17.7656 29.63 34.07 13.3716 54.183 13.3716C74.2956 13.3716 90.5999 29.63 90.5999 49.6857"
                fill="#95C11F"
            />
            <path d="M67 50L7 50" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M52.6001 72L68.6001 48L52.6001 24" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M65 47.9995L5 47.9995" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M50.6001 72L66.6001 48L50.6001 24" stroke="#333333" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M87.4344 46.3141C87.4344 66.3699 71.13 82.6283 51.0175 82.6283C30.9045 82.6283 14.6001 66.3699 14.6001 46.3141C14.6001 26.2584 30.9045 10 51.0175 10C71.13 10 87.4344 26.2584 87.4344 46.3141Z"
                stroke="#333333"
                strokeWidth="1.52387"
            />
        </svg>
    </Icon>
);
