import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "./icon-component.types";

export const IconPencil: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" boxSize={3} {...props}>
        <path
            fill="currentColor"
            d="M3.894 15.022l-1.845 5.669a1 1 0 0 0 1.26 1.26l5.669-1.845a1 1 0 0 0 .398-1.658l-3.824-3.824a1 1 0 0 0-1.658.398zm1.396 2.169l1.518 1.518-2.25.732.732-2.25z"
        />
        <path
            fill="currentColor"
            d="M15.677 3.084L4.138 14.624a1 1 0 0 0 0 1.414l3.824 3.824a1 1 0 0 0 1.414 0l11.54-11.539c1.445-1.447 1.445-3.792 0-5.238l-.185-.173c-1.463-1.277-3.674-1.207-5.054.172zm3.734 1.331l.115.107.106.118c.532.65.495 1.643-.131 2.269L8.669 17.741l-2.41-2.41L17.091 4.499c.598-.597 1.535-.661 2.203-.177l.117.094z"
        />
    </Icon>
);
