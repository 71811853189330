import { Icon } from "@chakra-ui/react";
import { TIconComponent } from "../icon-component.types";

export const IconWhatsApp: TIconComponent = props => (
    <Icon viewBox="0 0 24 24" {...props}>
        <g transform="translate(2 2)">
            <path
                fill="currentColor"
                d="m14.582 11.985c-0.24882-0.12393-1.4721-0.72292-1.7002-0.80566-0.22805-0.082638-0.39392-0.12388-0.5598 0.12393-0.16588 0.24786-0.64274 0.8057-0.7879 0.97098-0.14511 0.16523-0.29026 0.18599-0.53908 0.061966-0.24882-0.12393-1.0506-0.38539-2.0009-1.2291-0.73967-0.65663-1.2391-1.4675-1.3842-1.7154-0.14516-0.24791-0.015518-0.38192 0.10906-0.50538 0.11197-0.11101 0.24886-0.28925 0.37325-0.43386 0.12443-0.14456 0.16588-0.24791 0.24882-0.41309 0.082939-0.16532 0.041493-0.30988-0.020771-0.43381-0.062168-0.12393-0.5598-1.3429-0.76713-1.8387-0.20198-0.4828-0.4071-0.41742-0.55985-0.42507-0.14496-0.0071755-0.31103-0.0086962-0.47686-0.0086962-0.16588 0-0.43547 0.061966-0.66356 0.30983-0.22805 0.24791-0.87084 0.84705-0.87084 2.0658 0 1.2189 0.89156 2.3964 1.016 2.5617 0.12443 0.16532 1.7545 2.6665 4.2506 3.7391 0.59361 0.25518 1.0571 0.40753 1.4185 0.52163 0.59604 0.18851 1.1385 0.1619 1.5672 0.098129 0.47806-0.071042 1.4721-0.59899 1.6795-1.1774 0.20732-0.57856 0.20732-1.0743 0.14511-1.1776-0.062168-0.10331-0.22805-0.16527-0.47686-0.28925m-4.54 6.1693h-0.0033424c-1.4852-5.703e-4 -2.9419-0.3977-4.2127-1.1482l-0.30225-0.17853-3.1326 0.81782 0.83612-3.0397-0.19677-0.31168c-0.82853-1.3115-1.2661-2.8273-1.2655-4.3837 0.0018152-4.5416 3.7148-8.2366 8.2803-8.2366 2.2108 7.6032e-4 4.2889 0.85869 5.8516 2.4157 1.5627 1.5569 2.4228 3.6265 2.4219 5.8275-0.0018629 4.542-3.7148 8.2373-8.2768 8.2373m7.0442-15.248c-1.8801-1.8733-4.3804-2.9054-7.0443-2.9065-5.4888 0-9.9561 4.4456-9.9583 9.9098-7.1623e-4 1.7467 0.45781 3.4516 1.3293 4.9546l-1.4127 5.1355 5.279-1.3781c1.4545 0.78955 3.0921 1.2057 4.7588 1.2062h0.0040586 4.78e-5c5.4883 0 9.9559-4.446 9.9581-9.9104 0.001002-2.6481-1.0339-5.1381-2.9139-7.0113"
            />
        </g>
    </Icon>
);
